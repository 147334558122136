import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import moment from 'moment';
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { differenceBy } from 'lodash';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getFlyerPdf, getPeopleFieldOptions, getPeopleList } from '../../../../actions';

// Helpers
import imageScaling from '@/utils/imageScaling';
import subjectsFilterOptions from '@/utils/subjectsFilterOptions';
import { pickerDate } from '@/utils/displayFormats';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';

const Download = memo(function Download({ flyer, job, isPrivateJob, onDownloadToggle, onFlyerSave }) {
  const dispatch = useDispatch();

  const {
    people: { fields, requesting: peopleRequesting }
  } = useSelector((state) => state.jobs);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [selectSubjectsFilter, setSelectSubjectsFilter] = useState(null);
  const [fieldSelected, setFieldSelected] = useState({ value: '', label: '' });
  const [fieldValues, setFieldValues] = useState([{ value: '', label: '' }]);
  const [fieldOptions, setFieldOptions] = useState([{ value: '', label: '' }]);

  const [peopleList, setPeopleList] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [removedRecipients, setRemovedRecipients] = useState([]);

  const [subjectPurchasedFilter, setSubjectPurchasedFilter] = useState('');
  const [subjectAccessedFilter, setSubjectAccessedFilter] = useState('');
  const [subjectFilterRemoved, setSubjectFilterRemoved] = useState([]);
  const [subjectEmailFilter, setSubjectEmailFilter] = useState('');

  const [subjectsPurchasedIds, setSubjectsPurchasedIds] = useState([]);
  const [subjectsNotPurchasedIds, setSubjectsNotPurchasedIds] = useState([]);
  const [subjectsAccessedIds, setSubjectsAccessedIds] = useState([]);
  const [subjectsNotAccessedIds, setSubjectsNotAccessedIds] = useState([]);
  const [subjectsWithEmailIds, setSubjectsWithEmailIds] = useState([]);

  const filterOptionsShow = fieldValues?.length > 0 && fieldValues[0].label !== '' ? true : false;

  const fetchPeopleListWithFilter = (filterSelect) => {
    dispatch(
      getPeopleList({ id: job.id, per_page: 10000, order: 'last_name', dir: 'asc', search_accuracy: 'exact', subject_filter: filterSelect }, ({ data }) => {
        const { job_status: jobStatus } = job;

        setPeopleList(data);
        setFieldSelected({ value: 'everyone', label: 'Everyone' });

        const peopleListAccessedIds = data.filter((person) => person?.accessed === true).map((person) => person.id);
        setSubjectsAccessedIds(peopleListAccessedIds);

        const peopleListNotAccessedIds = data.filter((person) => person?.accessed === false).map((person) => person.id);
        setSubjectsNotAccessedIds(peopleListNotAccessedIds);

        const peopleListPurchasedIds = data
          .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === true)
          .map((person) => person.id);
        setSubjectsPurchasedIds(peopleListPurchasedIds);

        const peopleListNotPurchasedIds = data
          .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === false)
          .map((person) => person.id);
        setSubjectsNotPurchasedIds(peopleListNotPurchasedIds);
      })
    );
  };

  const resetRecipientList = () => {
    const reset = [...recipients, ...subjectFilterRemoved].sort((a, b) => {
      const nameA = a.last_name.toLowerCase();
      const nameB = b.last_name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return reset;
  };

  const resetFilterByOptions = () => {
    if (subjectPurchasedFilter) {
      handleFilterByPurchasedOptions({ target: { name: subjectPurchasedFilter, checked: false } });
    }

    if (subjectAccessedFilter) {
      handleFilterByAccessedOptions({ target: { name: subjectAccessedFilter, checked: false } });
    }
  };

  const handleSubjectsFilterChange = (select) => {
    setSelectSubjectsFilter(select);

    // Reset other filters
    setFieldSelected({ value: '', label: '' });
    setFieldValues([{ value: '', label: '' }]);
    setRemovedRecipients([]);
    resetFilterByOptions();

    // Fetch new subjects based on filter
    fetchPeopleListWithFilter(select?.value);
  };

  const handleFieldSelectedChange = (select) => {
    setFieldSelected(select ? select : { value: '', label: '' });
    setFieldValues([{ value: '', label: '' }]);
    setRemovedRecipients([]);
    resetFilterByOptions();
  };

  const handleFieldValuesChange = (select) => {
    setFieldValues(select);
    resetFilterByOptions();
  };

  const handleRemoveRecipient = (recipient) => {
    const currentGroup = fieldSelected.value;
    const removePerson = peopleList.find((person) => person.id === recipient.id);
    const isDuplicateRecipient = recipients.filter((item) => item[currentGroup] === recipient[currentGroup]).length > 1 ? true : false;
    const newRecipients = recipients.filter((person) => person.id !== recipient.id);
    const allRecipientsHaveValue = newRecipients.every((item) => item[currentGroup]);

    // RemovedRecipients list is needed to know which subjects should not be added back,
    // in the useEffect as fieldValues change when another field is added or removed.
    setRemovedRecipients([...removedRecipients, removePerson]);
    setRecipients(newRecipients);

    // Remove group select if everyone is selected or if recipient has unique group value
    if (currentGroup === 'everyone' || isDuplicateRecipient === false) {
      setFieldValues(
        fieldValues.filter((field) => {
          return (
            removePerson[currentGroup === 'everyone' ? 'id' : currentGroup] !== field.value &&
            (!allRecipientsHaveValue || (allRecipientsHaveValue && field.value !== ''))
          );
        })
      );
    }
  };

  const handleFilterByPurchasedOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectPurchasedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let recipientsWithoutPurchases = currentRecipients.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
      let recipientsWithPurchases = currentRecipients.filter((subject) => subjectsPurchasedIds.includes(subject.id));

      if (subjectAccessedFilter) {
        if (subjectAccessedFilter === 'subjectsNotAccessed') {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
        } else {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject) => subjectsAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject) => subjectsAccessedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectAccessedFilter) {
        const withoutAccessed = currentRecipients.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
        const withAccessed = currentRecipients.filter((subject) => subjectsAccessedIds.includes(subject.id));

        const newRecipients = subjectAccessedFilter === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }

    // Reset no email filter
    setSubjectEmailFilter('');
  };

  const handleFilterByAccessedOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectAccessedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let withoutAccessed = currentRecipients.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
      let withAccessed = currentRecipients.filter((subject) => subjectsAccessedIds.includes(subject.id));

      if (subjectPurchasedFilter) {
        if (subjectPurchasedFilter === 'subjectsNoPurchase') {
          withoutAccessed = withoutAccessed.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
        } else {
          withoutAccessed = withoutAccessed.filter((subject) => subjectsPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject) => subjectsPurchasedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectPurchasedFilter) {
        const recipientsWithoutPurchases = currentRecipients.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
        const recipientsWithPurchases = currentRecipients.filter((subject) => subjectsPurchasedIds.includes(subject.id));

        const newRecipients = subjectPurchasedFilter === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }

    // Reset no email filter
    setSubjectEmailFilter('');
  };

  const handleFilterByEmailOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectEmailFilter(checked ? name : '');

    if (checked) {
      const recipientsWithoutEmail = recipients.filter((subject) => !subject.email && !subject.email_2 && !subject.email_3);
      const recipientsWithEmail = recipients.filter((subject) => subject.email || subject.email_2 || subject.email_3);

      setRecipients(recipientsWithoutEmail);
      setSubjectsWithEmailIds(recipientsWithEmail.map((subject) => subject.id));
      setSubjectFilterRemoved((prevRemovedSubjects) => [...prevRemovedSubjects, ...recipientsWithEmail]);
    } else {
      const recipientsRemoved = subjectFilterRemoved.filter((subject) => subjectsWithEmailIds.includes(subject.id));

      setSubjectsWithEmailIds([]);
      setRecipients((prevState) => [...prevState, ...recipientsRemoved].sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())));
      setSubjectFilterRemoved((prevState) => prevState.filter((subject) => !subjectsWithEmailIds.includes(subject.id)));
    }
  };

  const handleDownloadRequest = () => {
    // Check if job has changed since last time flyer updated
    // if true save flyer first to update it before download
    if (moment(flyer.updated_at).isBefore(job.updated_at)) {
      onFlyerSave(() => dispatch(getFlyerPdf({ ...flyer, subject_fields: { id: recipients.map((recipient) => recipient.id) } })));
    } else {
      dispatch(getFlyerPdf({ ...flyer, subject_fields: { id: recipients.map((recipient) => recipient.id) } }));
    }

    setShowConfirmation(true);
  };

  const recipientRow = ({ recipient }) => {
    return (
      <figure key={recipient.id} className="flex middle nowrap job-marketing-flyers__email-recipient animate">
        <div className="job-marketing-flyers__email-recipient-image-box">
          <LazyLoadImage
            className="job-marketing-flyers__email-recipient-image"
            src={recipient.session_photo_url ? imageScaling({ url: recipient.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
            alt={recipient.session_photo_filename}
            height={40}
            draggable="false"
          />
        </div>
        <figcaption className="flex between middle">
          <div className="flex column job-marketing-flyers__email-recipient-details">
            <p className="text--bold text--capitalize text--truncate text--nomargin">{recipient.last_name + ', ' + recipient.first_name} </p>
            <p className="text--small text--nomargin">{`photos: ${recipient.photos_count}`} </p>
            <p className="text--truncate text--small text--nomargin">
              {fieldSelected.value !== 'everyone'
                ? recipient[fieldSelected.value]
                  ? `${fieldSelected.label}: ${
                      fieldSelected.value === 'session_start' ? pickerDate(recipient[fieldSelected.value] ?? '') : recipient[fieldSelected.value]
                    }`
                  : ''
                : ''}
            </p>
          </div>
          <button className="button button--clean button-noborder" type="button" onClick={() => handleRemoveRecipient(recipient)}>
            <i className="icon-trash"></i>
          </button>
        </figcaption>
      </figure>
    );
  };

  useEffect(() => {
    if (fieldSelected.value === 'everyone') {
      const newFieldOptions = peopleList
        .sort((a, b) => a.last_name.localeCompare(b.last_name))
        .map((person) => ({ label: `${person.last_name}, ${person.first_name}`, value: person.id }));

      setFieldOptions(newFieldOptions);
      setFieldValues(newFieldOptions);
    } else {
      const fieldLabel = fieldSelected.label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      const fieldValue = fieldSelected.value;

      setFieldOptions([
        ...(fieldValue.match(/first_name|last_name/) ? [] : [{ label: `${fieldLabel} Empty`, value: '' }]),
        ...(fields[fieldSelected.value] ? fields[fieldSelected.value].sort().map((field) => ({ label: field, value: field })) : [])
      ]);
    }
  }, [fieldSelected]);

  useEffect(() => {
    if (fieldSelected.value && fieldValues.length > 0) {
      const isFilteringForEmptyData = fieldValues.some((field) => field.value === '' && field.label.includes('Empty'));

      let newRecipients = [];
      let newRemovedRecipients = [];

      if (fieldSelected.value === 'everyone') {
        newRecipients = peopleList.filter((person) => fieldValues.find((field) => field.value === person.id) && !subjectFilterRemoved.includes(person));
      } else if (fieldSelected.value === 'session_start') {
        // Since subjects use datetime and field options only date
        newRecipients = peopleList.filter((person) => fieldValues.find((field) => field.value === pickerDate(person[fieldSelected.value] ?? '')));
      } else {
        newRecipients = peopleList.filter((person) => {
          if (isFilteringForEmptyData && !person[fieldSelected.value]) {
            return true;
          }

          return fieldValues.find((field) => field.value === person[fieldSelected.value]);
        });
      }

      // Remove subjects from the RemovedRecipients list because their field value has been removed.
      if (removedRecipients.length > 0) {
        newRemovedRecipients = removedRecipients.filter((person) => {
          if (fieldSelected.value === 'everyone') {
            return true;
          } else {
            return fieldValues.some((fieldValue) => fieldValue.value === person[fieldSelected.value]);
          }
        });

        // Remove people that were on the removedRecipients list.
        newRecipients = newRecipients.filter((person) => !newRemovedRecipients.some((removedPerson) => removedPerson.id === person.id));
      }

      setRemovedRecipients(newRemovedRecipients);
      setRecipients(newRecipients);
    } else {
      setRecipients([]);
      setRemovedRecipients([]);
    }
  }, [fieldValues]);

  useEffect(() => {
    if (subjectPurchasedFilter) {
      handleFilterByPurchasedOptions({
        target: {
          name: subjectPurchasedFilter,
          checked: true
        }
      });
    }

    if (subjectAccessedFilter) {
      handleFilterByAccessedOptions({
        target: {
          name: subjectAccessedFilter,
          checked: true
        }
      });
    }
  }, [subjectPurchasedFilter, subjectAccessedFilter]);

  useEffect(() => {
    setShowConfirmation(!isPrivateJob);

    if (isPrivateJob) {
      // Set initial subject Filter Option
      const initialSubjectFilterOption = subjectsFilterOptions[0];
      setSelectSubjectsFilter(initialSubjectFilterOption);

      // Fetch people data with initial filter
      fetchPeopleListWithFilter(initialSubjectFilterOption?.value);

      // Get people field options
      dispatch(getPeopleFieldOptions({ id: job.id }));
    }
  }, []);

  return (
    <div className="job-marketing-flyers__download">
      {showConfirmation ? (
        <aside className="modal animate">
          <div className="modal__box modal__box--dark modal__box--xsmall">
            <button className="button button--action modal__close" name="close" type="button" onClick={onDownloadToggle}>
              <i className="icon-close"></i>
            </button>

            <main className="flex column center middle modal__content">
              <h2>Hang Tight...</h2>
              <p className="text--tall">
                …and imagine the elevator music{' '}
                <span role="img" aria-label="music notes">
                  🎶
                </span>
                . Your download request is processing and will arrive in your email inbox soon!
              </p>
              <footer>
                <button className="button" name="gotit" onClick={onDownloadToggle}>
                  Got it!
                </button>
              </footer>
            </main>
          </div>
        </aside>
      ) : (
        <aside className="modal animate">
          <div className="modal__box modal__box--secondary">
            <button className="button button--action modal__close" name="close" type="button" onClick={onDownloadToggle}>
              <i className="icon-close"></i>
            </button>

            <main className="flex modal__content">
              <section className="flex-5 flex-12-sm modal__content-section modal__content-section--dark">
                <h3>Select Subjects</h3>
                <p>Create Flyers for the following subjects:</p>
                <Select
                  className="select select--capitalize mb-10"
                  classNamePrefix="select"
                  isLoading={peopleRequesting}
                  value={selectSubjectsFilter}
                  options={subjectsFilterOptions}
                  onChange={handleSubjectsFilterChange}
                />
                <Select
                  className="select select--capitalize mb-10"
                  classNamePrefix="select"
                  placeholder="Select a group"
                  isClearable={true}
                  isLoading={peopleRequesting}
                  value={fieldSelected.value ? fieldSelected : ''}
                  options={[
                    { value: 'everyone', label: 'Everyone' },
                    ...Object.keys(fields).map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }))
                  ]}
                  onChange={handleFieldSelectedChange}
                />
                <Select
                  className="select"
                  classNamePrefix="select"
                  isMulti={true}
                  isLoading={peopleRequesting}
                  maxMenuHeight={350}
                  value={fieldValues.length > 0 && (fieldValues[0].value || fieldValues[0].label) ? fieldValues : null}
                  isClearable={true}
                  options={fieldOptions}
                  onChange={handleFieldValuesChange}
                />
                {filterOptionsShow && (
                  <div className="mt-20 animate">
                    <p>Optional Filters:</p>
                    <h6 className="mt-10 text--uppercase">Filter by purchase</h6>
                    <fieldset>
                      <input
                        type="checkbox"
                        className="hidden"
                        name="subjectsPurchase"
                        id="subjectsPurchase"
                        checked={subjectPurchasedFilter === 'subjectsPurchase'}
                        onChange={handleFilterByPurchasedOptions}
                      />
                      <label htmlFor="subjectsPurchase" className="label--checkbox">
                        Only Subjects with Purchases
                      </label>
                    </fieldset>
                    <fieldset>
                      <input
                        type="checkbox"
                        className="hidden"
                        name="subjectsNoPurchase"
                        id="subjectsNoPurchase"
                        checked={subjectPurchasedFilter === 'subjectsNoPurchase'}
                        onChange={handleFilterByPurchasedOptions}
                      />
                      <label htmlFor="subjectsNoPurchase" className="label--checkbox">
                        Only Subjects without Purchases
                      </label>
                    </fieldset>
                    <h6 className="mt-10 text--uppercase">Filter by accessed gallery</h6>
                    <fieldset>
                      <input
                        type="checkbox"
                        className="hidden"
                        name="subjectsAccessed"
                        id="subjectsAccessed"
                        checked={subjectAccessedFilter === 'subjectsAccessed'}
                        onChange={handleFilterByAccessedOptions}
                      />
                      <label htmlFor="subjectsAccessed" className="label--checkbox">
                        Only Subjects That Accessed Gallery
                      </label>
                    </fieldset>
                    <fieldset>
                      <input
                        type="checkbox"
                        className="hidden"
                        name="subjectsNotAccessed"
                        id="subjectsNotAccessed"
                        checked={subjectAccessedFilter === 'subjectsNotAccessed'}
                        onChange={handleFilterByAccessedOptions}
                      />
                      <label htmlFor="subjectsNotAccessed" className="label--checkbox">
                        Only Subjects That Have NOT Accessed Gallery
                      </label>
                    </fieldset>
                    <h6 className="mt-10 text--uppercase">Filter by email</h6>
                    <fieldset>
                      <input
                        id="subjectsNoEmail"
                        className="hidden"
                        name="subjectsNoEmail"
                        type="checkbox"
                        checked={subjectEmailFilter === 'subjectsNoEmail'}
                        onChange={handleFilterByEmailOptions}
                      />
                      <label htmlFor="subjectsNoEmail" className="label--checkbox">
                        Only Subjects without an Email
                      </label>
                    </fieldset>
                  </div>
                )}
              </section>
              <section className="flex-7 flex-12-sm modal__content-section">
                <header className="modal__header">
                  <h3>Subjects Included</h3>
                  {!peopleRequesting && (
                    <p>The pdf will includes flyers of the following {`${recipients.length === 1 ? 'subject' : 'subjects'} (${recipients.length}):`} </p>
                  )}
                </header>
                <div className="job-marketing-flyers__email-recipients">
                  {fieldSelected?.value && peopleList.length === 0 ? (
                    <p className="text--bold job-marketing-flyers__email-error animate">
                      There are no subjects matching the criteria you specified. Please update your selection.
                    </p>
                  ) : peopleRequesting ? (
                    <TableLoader rows={10} rowHeight={20} />
                  ) : (
                    <>{recipients.map((recipient) => recipientRow({ recipient }))}</>
                  )}
                </div>
                <footer className="modal__footer modal__footer--fixed flex end gap-10">
                  <button className="button button--outline" type="button" name="cancel" onClick={onDownloadToggle}>
                    Cancel
                  </button>
                  <button
                    className="button"
                    name="send"
                    type="button"
                    onClick={handleDownloadRequest}
                    data-loading={peopleRequesting}
                    disabled={peopleRequesting || !recipients.length}
                  >
                    Next
                  </button>
                </footer>
              </section>
            </main>
          </div>
        </aside>
      )}
    </div>
  );
});

Download.propTypes = {
  job: PropTypes.object.isRequired,
  flyer: PropTypes.object.isRequired,
  isPrivateJob: PropTypes.bool.isRequired
};

Download.defaultProps = {
  job: {},
  flyer: {},
  isPrivateJob: false
};

export default Download;
