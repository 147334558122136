import { useState } from 'react';
import { Link } from 'react-router-dom';

// Plugins
import Select from 'react-select';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination } from 'swiper/modules';

// Components
import DownloadFileLink from '../DownloadFileLink';

// Images
import prePictureDayBanner1 from '@/assets/images/collateral/pre-picture-day/banners/announcement-template-1.png';
import prePictureDayBanner2 from '@/assets/images/collateral/pre-picture-day/banners/announcement-template-2.png';
import prePictureDayBanner3 from '@/assets/images/collateral/pre-picture-day/banners/announcement-template-3.png';
import prePictureDayBanner4 from '@/assets/images/collateral/pre-picture-day/banners/announcement-template-4.png';

import prePictureDayAPBanner1 from '@/assets/images/collateral/pre-picture-day/banners/announcement-ap-template-1.png';
import prePictureDayAPBanner2 from '@/assets/images/collateral/pre-picture-day/banners/announcement-ap-template-2.png';
import prePictureDayAPBanner3 from '@/assets/images/collateral/pre-picture-day/banners/announcement-ap-template-3.png';
import prePictureDayAPBanner4 from '@/assets/images/collateral/pre-picture-day/banners/announcement-ap-template-4.png';

import prePictureDayOfferBanner1 from '@/assets/images/collateral/pre-picture-day/banners/announcement-offer-template-1.png';
import prePictureDayOfferBanner2 from '@/assets/images/collateral/pre-picture-day/banners/announcement-offer-template-2.png';

import prePictureDayPoster1 from '@/assets/images/collateral/pre-picture-day/posters/announcement-template-1.png';
import prePictureDayPoster2 from '@/assets/images/collateral/pre-picture-day/posters/announcement-template-2.png';
import prePictureDayPoster3 from '@/assets/images/collateral/pre-picture-day/posters/announcement-template-3.png';
import prePictureDayPoster4 from '@/assets/images/collateral/pre-picture-day/posters/announcement-template-4.png';

import prePictureDayAPPoster1 from '@/assets/images/collateral/pre-picture-day/posters/announcement-ap-template-1.png';
import prePictureDayAPPoster2 from '@/assets/images/collateral/pre-picture-day/posters/announcement-ap-template-2.png';
import prePictureDayAPPoster3 from '@/assets/images/collateral/pre-picture-day/posters/announcement-ap-template-3.png';
import prePictureDayAPPoster4 from '@/assets/images/collateral/pre-picture-day/posters/announcement-ap-template-4.png';

import prePictureDayOfferPoster1 from '@/assets/images/collateral/pre-picture-day/posters/announcement-offer-template-1.png';
import prePictureDayOfferPoster2 from '@/assets/images/collateral/pre-picture-day/posters/announcement-offer-template-2.png';
import prePictureDayOfferPoster3 from '@/assets/images/collateral/pre-picture-day/posters/announcement-offer-template-3.png';
import prePictureDayOfferPoster4 from '@/assets/images/collateral/pre-picture-day/posters/announcement-offer-template-4.png';

import prePictureAnnMultDatesPoster1 from '@/assets/images/collateral/pre-picture-day/posters/announcement-multi-dates-template-1.png';
import prePictureAnnMultDatesPoster2 from '@/assets/images/collateral/pre-picture-day/posters/announcement-multi-dates-template-2.png';

import prePictureAnnWithDatePoster1 from '@/assets/images/collateral/pre-picture-day/posters/announcement-with-date-template-1.png';
import prePictureAnnWithDatePoster2 from '@/assets/images/collateral/pre-picture-day/posters/announcement-with-date-template-2.png';

import prePictureDayGraphics1 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-template-1.png';
import prePictureDayGraphics2 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-template-2.png';

import prePictureDayAPGraphics1 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-ap-template-1.png';
import prePictureDayAPGraphics2 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-ap-template-2.png';

import prePictureDayOfferGraphics1 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-offer-template-1.png';
import prePictureDayOfferGraphics2 from '@/assets/images/collateral/pre-picture-day/graphics/announcement-offer-template-2.png';

import prePictureDayCards1 from '@/assets/images/collateral/pre-picture-day/cards/reminder-template-1.png';
import prePictureDayCards2 from '@/assets/images/collateral/pre-picture-day/cards/reminder-template-2.png';
import prePictureDayCards3 from '@/assets/images/collateral/pre-picture-day/cards/reminder-template-3.png';
import prePictureDayCards4 from '@/assets/images/collateral/pre-picture-day/cards/reminder-template-4.png';

import prePictureDayAPCards1 from '@/assets/images/collateral/pre-picture-day/cards/reminder-ap-template-1.png';
import prePictureDayAPCards2 from '@/assets/images/collateral/pre-picture-day/cards/reminder-ap-template-2.png';
import prePictureDayAPCards3 from '@/assets/images/collateral/pre-picture-day/cards/reminder-ap-template-3.png';
import prePictureDayAPCards4 from '@/assets/images/collateral/pre-picture-day/cards/reminder-ap-template-4.png';

import prePictureDayOfferCards1 from '@/assets/images/collateral/pre-picture-day/cards/reminder-offer-template-1.png';
import prePictureDayOfferCards2 from '@/assets/images/collateral/pre-picture-day/cards/reminder-offer-template-2.png';
import prePictureDayOfferCards3 from '@/assets/images/collateral/pre-picture-day/cards/reminder-offer-template-3.png';
import prePictureDayOfferCards4 from '@/assets/images/collateral/pre-picture-day/cards/reminder-offer-template-4.png';

import pictureDayBanner1 from '@/assets/images/collateral/picture-day/banners/pictureDay-template-1.png';
import pictureDayBanner2 from '@/assets/images/collateral/picture-day/banners/pictureDay-template-2.png';
import pictureDayBanner3 from '@/assets/images/collateral/picture-day/banners/pictureDay-template-3.png';
import pictureDayBanner4 from '@/assets/images/collateral/picture-day/banners/pictureDay-template-4.png';

import pictureDayAPBanner1 from '@/assets/images/collateral/picture-day/banners/pictureDay-ap-template-1.png';
import pictureDayAPBanner2 from '@/assets/images/collateral/picture-day/banners/pictureDay-ap-template-2.png';
import pictureDayAPBanner3 from '@/assets/images/collateral/picture-day/banners/pictureDay-ap-template-3.png';
import pictureDayAPBanner4 from '@/assets/images/collateral/picture-day/banners/pictureDay-ap-template-4.png';

import pictureDayOfferBanner1 from '@/assets/images/collateral/picture-day/banners/pictureDay-offer-template-1.png';
import pictureDayOfferBanner2 from '@/assets/images/collateral/picture-day/banners/pictureDay-offer-template-2.png';

import pictureDayPoster1 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-1.png';
import pictureDayPoster2 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-2.png';
import pictureDayPoster3 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-3.png';
import pictureDayPoster4 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-4.png';
import pictureDayPoster5 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-5.png';
import pictureDayPoster6 from '@/assets/images/collateral/picture-day/posters/pictureDay-template-6.png';

import pictureDayAPPoster1 from '@/assets/images/collateral/picture-day/posters/pictureDay-ap-template-1.png';
import pictureDayAPPoster2 from '@/assets/images/collateral/picture-day/posters/pictureDay-ap-template-2.png';
import pictureDayAPPoster3 from '@/assets/images/collateral/picture-day/posters/pictureDay-ap-template-3.png';
import pictureDayAPPoster4 from '@/assets/images/collateral/picture-day/posters/pictureDay-ap-template-4.png';

import pictureDayOfferPoster1 from '@/assets/images/collateral/picture-day/posters/pictureDay-offer-template-1.png';
import pictureDayOfferPoster2 from '@/assets/images/collateral/picture-day/posters/pictureDay-offer-template-2.png';
import pictureDayOfferPoster3 from '@/assets/images/collateral/picture-day/posters/pictureDay-offer-template-3.png';
import pictureDayOfferPoster4 from '@/assets/images/collateral/picture-day/posters/pictureDay-offer-template-4.png';

import pictureDayOfferGraphics1 from '@/assets/images/collateral/picture-day/graphics/announcement-offer-template-1.png';
import pictureDayOfferGraphics2 from '@/assets/images/collateral/picture-day/graphics/announcement-offer-template-2.png';

import postPictureDayPoster1 from '@/assets/images/collateral/post-picture-day/posters/publishDay-poster-template-1.png';
import postPictureDayPoster2 from '@/assets/images/collateral/post-picture-day/posters/publishDay-poster-template-2.png';

import postPictureDayGraphics1 from '@/assets/images/collateral/post-picture-day/graphics/publishDay-template-1.png';
import postPictureDayGraphics2 from '@/assets/images/collateral/post-picture-day/graphics/publishDay-template-2.png';

import postPictureDayOfferGraphics1 from '@/assets/images/collateral/post-picture-day/graphics/publishDay-offer-template-1.png';
import postPictureDayOfferGraphics2 from '@/assets/images/collateral/post-picture-day/graphics/publishDay-offer-template-2.png';

// Styles
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';

const kitOptions = [
  { label: 'Pre-Picture Day', value: 'prePictureDay' },
  { label: 'Picture Day', value: 'pictureDay' },
  { label: 'Post-Picture Day', value: 'postPictureDay' }
];

const kitStructure = {
  prePictureDay: [
    { label: 'Banners', value: 'banners' },
    { label: 'Posters', value: 'posters' },
    { label: 'Social Graphics', value: 'graphics' },
    { label: 'Cards', value: 'cards' }
  ],
  pictureDay: [
    { label: 'Banners', value: 'banners' },
    { label: 'Posters', value: 'posters' },
    { label: 'Social Graphics', value: 'graphics' }
  ],
  postPictureDay: [
    { label: 'Posters', value: 'posters' },
    { label: 'Social Graphics', value: 'graphics' }
  ]
};

const kitData = {
  prePictureDay: {
    banners: [
      {
        name: 'Announcement Banner',
        description:
          "8'x4' banner announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)",
        pdf: 'https://media.photoday.io/marketingkit/banner/announcement_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/announcement_banner_8x4.psdt',
        images: [prePictureDayBanner1, prePictureDayBanner2, prePictureDayBanner3, prePictureDayBanner4]
      },
      {
        name: 'Announcement Banner with AdvancePay',
        description:
          "8'x4' banner announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)",
        pdf: 'https://media.photoday.io/marketingkit/banner/announcement_ap_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/announcement_ap_banner_8x4.psdt',
        images: [prePictureDayAPBanner1, prePictureDayAPBanner2, prePictureDayAPBanner3, prePictureDayAPBanner4]
      },
      {
        name: 'Announcement Banner with Offer',
        description:
          "8'x4' banner announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)",
        pdf: 'https://media.photoday.io/marketingkit/banner/announcement_offer_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/announcement_offer_banner_8x4.psdt',
        images: [prePictureDayOfferBanner1, prePictureDayOfferBanner2]
      }
    ],
    posters: [
      {
        name: 'Announcement Poster',
        description:
          '24”x36” poster announcing the upcoming picture days. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/announcement_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/announcement_poster_24x36.psdt',
        images: [prePictureDayPoster1, prePictureDayPoster2, prePictureDayPoster3, prePictureDayPoster4]
      },
      {
        name: 'Announcement Poster with AdvancePay',
        description:
          '24”x36” poster announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)\n• Picture Day date',
        pdf: 'https://media.photoday.io/marketingkit/poster/announcement_ap_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/announcement_ap_poster_24x36.psdt',
        images: [prePictureDayAPPoster1, prePictureDayAPPoster2, prePictureDayAPPoster3, prePictureDayAPPoster4]
      },
      {
        name: 'Announcement Poster with Offer',
        description:
          '24”x36” poster announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)\n• Picture Day date',
        pdf: 'https://media.photoday.io/marketingkit/poster/announcement_offer_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/announcement_offer_poster_24x36.psdt',
        images: [prePictureDayOfferPoster1, prePictureDayOfferPoster2, prePictureDayOfferPoster3, prePictureDayOfferPoster4]
      },
      {
        name: 'Announcement Poster with Multiple Dates',
        description:
          '24”x36” poster announcing the upcoming picture days. Template includes a variety of headlines to direct customers to their organization page, plus space for optional:\n• QR code (which can lead to the organization page)\n• Picture Day dates for multiple grades/teams',
        pdf: 'https://media.photoday.io/marketingkit/poster/new-announcement_poster_24x36-1.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/new-announcement_poster_24x36-1.psdt',
        images: [prePictureAnnMultDatesPoster1, prePictureAnnMultDatesPoster2]
      },
      {
        name: 'Announcement Poster with Date',
        description:
          '24”x36” poster announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/new-announcement_poster_24x36-2.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/new-announcement_poster_24x36-2.psdt',
        images: [prePictureAnnWithDatePoster1, prePictureAnnWithDatePoster2]
      }
    ],
    graphics: [
      {
        name: 'Announcement Social Graphic',
        description:
          'Standard social graphic announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Access code\n• Picture Day date',
        pdf: 'https://media.photoday.io/marketingkit/social/picturedaysocial_1080x1080.pdf',
        psdt: 'https://media.photoday.io/marketingkit/social/picturedaysocial_1080x1080.psdt',
        images: [prePictureDayGraphics1, prePictureDayGraphics2]
      },
      {
        name: 'Announcement Social Graphic with AdvancePay',
        description:
          'Standard social graphic announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code',
        pdf: 'https://media.photoday.io/marketingkit/social/picturedaysocial_ap_1080x1080.pdf',
        psdt: 'https://media.photoday.io/marketingkit/social/picturedaysocial_ap_1080x1080.psdt',
        images: [prePictureDayAPGraphics1, prePictureDayAPGraphics2]
      },
      {
        name: 'Announcement Social Graphic with Offer',
        description:
          'Standard social graphic announcing the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info',
        pdf: 'https://media.photoday.io/marketingkit/social/pre-pictureday_social_offer_1080x1080.pdf',
        psdt: 'https://s3.amazonaws.com/media.photoday.io/marketingkit/social/pre-pictureday_social_offer_1080x1080.psdt',
        images: [prePictureDayOfferGraphics1, prePictureDayOfferGraphics2]
      }
    ],
    cards: [
      {
        name: 'Reminder Card',
        description:
          '3.5”x2” card reminding subjects and parents about the upcoming picture day. Template includes a variety of headlines and space for optional:\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/reminder/remindercard.pdf',
        psdt: 'https://media.photoday.io/marketingkit/reminder/remindercard.psdt',
        images: [prePictureDayCards1, prePictureDayCards2, prePictureDayCards3, prePictureDayCards4]
      },
      {
        name: 'Reminder Card with AdvancePay',
        description:
          '3.5”x2” card reminding subjects and parents about the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/reminder/remindercard_ap.pdf',
        psdt: 'https://media.photoday.io/marketingkit/reminder/remindercard_ap.psdt',
        images: [prePictureDayAPCards1, prePictureDayAPCards2, prePictureDayAPCards3, prePictureDayAPCards4]
      },
      {
        name: 'Reminder Card with Offer',
        description:
          '3.5”x2” card reminding subjects and parents about the upcoming picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/reminder/remindercard_offer.pdf',
        psdt: 'https://media.photoday.io/marketingkit/reminder/remindercard_offer.psdt',
        images: [prePictureDayOfferCards1, prePictureDayOfferCards2, prePictureDayOfferCards3, prePictureDayOfferCards4]
      }
    ]
  },
  pictureDay: {
    banners: [
      {
        name: 'Picture Day Banner',
        description:
          '8’x4’ banner for picture day. Template includes a variety of headlines and instructions to direct customers to their gallery or organization page, plus space for optional:\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/banner/pictureday_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/pictureday_banner_8x4.psdt',
        images: [pictureDayBanner1, pictureDayBanner2, pictureDayBanner3, pictureDayBanner4]
      },
      {
        name: 'Picture Day Banner with AdvancePay',
        description:
          '8’x4’ banner for picture day with AdvancePay. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/banner/pictureDay_ap_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/pictureday_ap_banner_8x4 .psdt',
        images: [pictureDayAPBanner1, pictureDayAPBanner2, pictureDayAPBanner3, pictureDayAPBanner4]
      },
      {
        name: 'Picture Day Banner with Offer',
        description:
          '8’x4’ banner for picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/banner/pictureday_offer_banner_8x4.pdf',
        psdt: 'https://media.photoday.io/marketingkit/banner/pictureday_offer_banner_8x4.psdt',
        images: [pictureDayOfferBanner1, pictureDayOfferBanner2]
      }
    ],
    posters: [
      {
        name: 'Picture Day Poster',
        description:
          '24”x36” poster for picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, a school-specific background option, and space for optional:\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/pictureday_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/pictureday_poster_24x36.psdt',
        images: [pictureDayPoster1, pictureDayPoster2, pictureDayPoster3, pictureDayPoster4, pictureDayPoster5, pictureDayPoster6]
      },
      {
        name: 'Picture Day Poster with AdvancePay',
        description:
          '24”x36” poster for picture day with AdvancePay. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• AdvancePay offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/pictureday_ap_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/pictureday_ap_poster_24x36.psdt',
        images: [pictureDayAPPoster1, pictureDayAPPoster2, pictureDayAPPoster3, pictureDayAPPoster4]
      },
      {
        name: 'Picture Day Poster with Offer',
        description:
          '24”x36” poster for picture day with a special offer. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/pictureday_offer_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/pictureday_offer_poster_24x36.psdt',
        images: [pictureDayOfferPoster1, pictureDayOfferPoster2, pictureDayOfferPoster3, pictureDayOfferPoster4]
      }
    ],
    graphics: [
      {
        name: 'Picture Day Social Graphic with Offer',
        description:
          'Standard social graphic to post on picture day. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code',
        pdf: 'https://media.photoday.io/marketingkit/social/pictureday_social_offer_1080x1080.pdf',
        psdt: 'https://media.photoday.io/marketingkit/social/pictureday_social_offer_1080x1080.psdt',
        images: [pictureDayOfferGraphics1, pictureDayOfferGraphics2]
      }
    ]
  },
  postPictureDay: {
    posters: [
      {
        name: 'Post-Picture Day Poster',
        description:
          '24"x36" poster for sharing after publishing a gallery. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• QR code (which can lead to the gallery, organization page, or texting to subscribe)',
        pdf: 'https://media.photoday.io/marketingkit/poster/post-pictureday_poster_24x36.pdf',
        psdt: 'https://media.photoday.io/marketingkit/poster/post-pictureday_poster_24x36.psdt',
        images: [postPictureDayPoster1, postPictureDayPoster2]
      }
    ],
    graphics: [
      {
        name: 'Post-Picture Day Social Graphic',
        description:
          'Standard social graphic to post after publishing a gallery. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Access code',
        pdf: 'https://media.photoday.io/marketingkit/social/pictureday_social_publish_1080x1080.pdf',
        psdt: 'https://media.photoday.io/marketingkit/social/pictureday_social_publish_1080x1080.psdt',
        images: [postPictureDayGraphics1, postPictureDayGraphics2]
      },
      {
        name: 'Post-Picture Day Social Graphic with Offer',
        description:
          'Standard social graphic to post after publishing a gallery. Template includes a variety of headlines to direct customers to their gallery or organization page, plus space for optional:\n• Offer info\n• Access code',
        pdf: 'https://media.photoday.io/marketingkit/social/pictureday_social_publish_offer_1080x1080.pdf',
        psdt: 'https://media.photoday.io/marketingkit/social/pictureday_social_publish_offer_1080x1080.psdt',
        images: [postPictureDayOfferGraphics1, postPictureDayOfferGraphics2]
      }
    ]
  }
};

const Marketing = () => {
  const [activeSection, setActiveSection] = useState('banners');
  const [activeOption, setActiveOption] = useState(kitOptions[0]);

  const handleKitOptionChange = (option) => {
    setActiveOption(option);

    if (option.value === 'postPictureDay') {
      setActiveSection('posters');
    } else {
      setActiveSection('banners');
    }
  };

  return (
    <>
      <header className="flex middle resources-container__header">
        <h2 className="text--nomargin">Marketing Kit</h2>
      </header>
      <p>
        We've created everything you need to have a successful picture day with PhotoDay! You can download and customize any of the below templates to fit your
        business needs. <strong>We recommend customizing the templates in Adobe Acrobat (PDF) or Photoshop (PSDT)</strong>
      </p>
      <p>
        All uses of the PhotoDay logo or other branding elements are subject to{' '}
        <a href="https://www.photoday.io/marks_usage_agreement/" target="_blank" rel="noopener noreferrer">
          PhotoDay's Marks Usage Agreement
        </a>
        .
      </p>
      <Select className="flex-2 select mt-30" classNamePrefix="select" value={activeOption} options={kitOptions} onChange={handleKitOptionChange} />
      <nav className="flex gap-10 mt-10">
        {kitStructure[activeOption?.value].map((item, index) => (
          <button
            key={index}
            className={`button ${activeSection === item.value ? '' : 'button--lean'}`}
            name={item.value}
            type="button"
            onClick={() => setActiveSection(item.value)}
          >
            {item.label}
          </button>
        ))}
      </nav>
      <section className={`animate ${activeSection === 'banners' ? '' : 'hidden'}`}>
        <article className="panel panel--light">
          <h4>Banner Templates</h4>
          <p className="text--nomargin">
            <strong>Use:</strong> Attach banner to a fence or tent.
            <br />
            <strong>Suggestions:</strong> 8' x 4'
          </p>
        </article>
        <ul className="grid row-2 row-1-sm gap-20">
          {kitData[activeOption?.value]?.banners?.map((item, index) => (
            <li className="resources-container__item" key={index}>
              <div className="example-image">
                <Swiper spaceBetween={30} effect={'fade'} fadeEffect={{ crossFade: true }} pagination={{ clickable: true }} modules={[Pagination, EffectFade]}>
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={image} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <DownloadFileLink title={item.name} description={item.description} filename={item.pdf} psdtFilename={item.psdt} />
            </li>
          ))}
        </ul>
      </section>
      <section className={`animate ${activeSection === 'posters' ? '' : 'hidden'}`}>
        <article className="panel panel--light">
          <h4>Poster Templates</h4>
          <p>
            <strong>Use:</strong> Affix to bulletin board or display on an easel.
            <br />
            <strong>Suggestions:</strong> 24" x 36"
          </p>
        </article>
        <ul className="grid row-3 row-1-sm gap-10">
          {kitData[activeOption?.value]?.posters?.map((item, index) => (
            <li className="resources-container__item" key={index}>
              <div className="example-image">
                <Swiper spaceBetween={30} effect={'fade'} fadeEffect={{ crossFade: true }} pagination={{ clickable: true }} modules={[Pagination, EffectFade]}>
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={image} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <DownloadFileLink title={item.name} description={item.description} filename={item.pdf} psdtFilename={item.psdt} />
            </li>
          ))}
        </ul>
      </section>
      <section className={`animate ${activeSection === 'graphics' ? '' : 'hidden'}`}>
        <article className="panel panel--light">
          <h4>Reminder Templates</h4>
          <p>
            <strong>Use:</strong> Share on your favorite social network.
            <br />
            <strong>Suggestions:</strong> 800px x 800px
          </p>
        </article>
        <ul className="grid row-3 row-1-sm gap-10">
          {kitData[activeOption?.value]?.graphics?.map((item, index) => (
            <li className="resources-container__item" key={index}>
              <div className="example-image">
                <Swiper spaceBetween={30} effect={'fade'} fadeEffect={{ crossFade: true }} pagination={{ clickable: true }} modules={[Pagination, EffectFade]}>
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={image} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <DownloadFileLink title={item.name} description={item.description} filename={item.pdf} psdtFilename={item.psdt} />
            </li>
          ))}
        </ul>
      </section>
      <section className={`animate ${activeSection === 'cards' ? '' : 'hidden'}`}>
        <article className="panel panel--light">
          <h4>Reminder Templates</h4>
          <p>
            <strong>Use:</strong> Printed reminder cards for parents and caregivers.
            <br />
            <strong>Suggestions:</strong> 3.5" x 2" (horizontal), 5” x 7” (High School Sports)
          </p>
        </article>
        <ul className="grid row-2 row-1-sm gap-20">
          {kitData[activeOption?.value]?.cards?.map((item, index) => (
            <li className="resources-container__item" key={index}>
              <div className="example-image">
                <Swiper spaceBetween={30} effect={'fade'} fadeEffect={{ crossFade: true }} pagination={{ clickable: true }} modules={[Pagination, EffectFade]}>
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={image} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <DownloadFileLink title={item.name} description={item.description} filename={item.pdf} psdtFilename={item.psdt} />
            </li>
          ))}
        </ul>
      </section>
      <footer className="panel mt-40">
        <p className="text--nomargin">
          <strong>Need sales tools?</strong> Check out the PhotoDay <Link to={`/collateral/sales`}>Sales Kit</Link> for everything you need to get your client
          organizations excited about PhotoDay
        </p>
      </footer>
    </>
  );
};

export default Marketing;
