import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

// Plugins
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';

// Helpers
import { Tooltip } from 'react-tippy';
import { convertToText } from '@/utils/currency';
import { formatCurrency } from '@/utils/displayFormats';

// Components
import DigitalProductSetup from './DigitalProductSetup';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';
import ItemComponent from '@/components/Shared/ProductCategoriesDDL/ItemComponent';

const DOWNLOAD_DIGITALS_ENABLED = import.meta.env.VITE_DOWNLOAD_DIGITALS_ENABLED === '1';
const BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';

const ProductsSelector = ({
  pdFee = 0,
  errors = {},
  requesting = false,
  isPackage = true,
  isPackagePresent,
  packageValues = {},
  productsAdded = [],
  digitalProductBundleAdded,
  bulkProductsAdded,
  formattedValue = '',
  currentSection = '',
  onProductsAdd = () => {},
  labId = '',
  onDragEnd = () => {},
  enableSubmit = false,
  shippingType,
  excludeProducts = [],
  onPriceChange = () => {},
  onBundlePricingTypeChange,
  onBundlePricingChange = () => {},
  onBundleTierChange,
  onQuantityChange = () => {},
  onProductRemove = () => {},
  onSelectedContentIndex = () => {}
}) => {
  const { entities } = useSelector((state) => state.productCategoriesDDL);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  const [firstSelected, setFirstSelected] = useState(0);
  const [lastSelected, setLastSelected] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedContentIndex, setSelectedContentIndex] = useState(null);

  const [filteredItems, setFilteredItems] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const priceFieldValues = isPackage
    ? {
        label: 'Package Price',
        message: "Enter the amount you'd like to set as the price for this package. We'll automatically calculate your markup.",
        baseCostText: convertToText(
          shippingType === 'bulk_shipping' ? (packageValues.baseCostCentsByShipping ?? packageValues.base_bulk_cost_cents) : packageValues.base_cost_cents,
          '$'
        ),
        markupCents: packageValues.markup_cents,
        photoDayFeeText: convertToText(Math.round(packageValues.price_cents * (pdFee / 100)), '$'),
        priceCents: packageValues.price_cents,
        minimumPriceCents: packageValues.minimumPriceCents,
        markupText: convertToText(packageValues.markup_cents, '$'),
        formattedValue
      }
    : {
        label: `${productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].name : 'Product'} Price`,
        message: "Enter the amount you'd like to set as the price for this product. We'll automatically calculate your markup.",
        baseCostText: productsAdded[selectedContentIndex]
          ? convertToText(
              shippingType === 'bulk_shipping'
                ? productsAdded[selectedContentIndex].baseCostCentsByShipping
                : productsAdded[selectedContentIndex].baseCostCents,
              '$'
            )
          : 0,
        markupCents: productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].markupCents : 0,
        markupText: productsAdded[selectedContentIndex] ? convertToText(productsAdded[selectedContentIndex].markupCents, '$') : 0,
        photoDayFeeText: convertToText(
          Math.round(productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].retailPrice * (pdFee / 100) : 0),
          '$'
        ),
        priceCents: productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].retailPrice : 0,
        minimumPriceCents: productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].minimumPriceCents : 0,
        formattedValue: productsAdded[selectedContentIndex] ? productsAdded[selectedContentIndex].formattedValue : 0
      };

  const baseCostTip = isPackage
    ? 'Costs set by lab'
    : currentSection === 'Print Products'
      ? 'Cost set by lab'
      : currentSection === 'Digital Products'
        ? 'Cost of digital download'
        : 'Click icon to see the cost breakdown';

  const initialValuesSetup = () => {
    const dropShipping = shippingType === 'drop_shipping';

    const initialAllProducts =
      Object.values(entities)?.length > 0
        ? entities[labId] && dropShipping
          ? entities[labId] && entities[labId]?.products && Object.values(entities[labId].products)
          : entities[labId] &&
            entities[labId]?.products &&
            Object.values(entities[labId]?.products)?.filter((item) => item?.bulk_price_cents || item?.is_digital_download)
        : [];

    const initialProducts = initialAllProducts?.length > 0 ? initialAllProducts.filter((product) => !excludeProducts?.includes(product.id)) : [];

    const productCategories = Object.values(entities)?.length > 0 ? entities[labId] && Object.values(entities[labId]?.productCategories) : [];

    let initialCategories = Object.values(productCategories ?? {});

    if (currentSection === 'Print Products') {
      initialCategories = initialCategories.filter((cat) => !cat.name.includes('Digital'));
    }
    if (currentSection === 'Digital Products') {
      initialCategories = initialCategories.filter((cat) => cat.name.includes('Digital'));
    }
    if (!dropShipping) {
      initialCategories = initialCategories.filter((cat) => initialAllProducts?.some((product) => cat.products.includes(product.id)));
    }

    const options = initialCategories.map((item) => ({ value: item.id, label: item.name }));

    setProducts(initialProducts);
    setAllProducts(initialAllProducts);
    setCategories(initialCategories);
    setCategoryOptions(options);

    if (initialCategories.length > 0 && (!currentCategory?.value || productsAdded.length === 0)) {
      const initialCategoryIndex = initialCategories.findIndex((category) => {
        return initialProducts.some((product) => product.category.id === category.id);
      });

      // Show First Category that contains items, selectproduct for preview and highlight first table item
      const initialCategory = initialCategoryIndex >= 0 ? initialCategories[initialCategoryIndex] : initialCategories[0];
      const initialProductSelected = initialProducts.find((product) => product.category.id === initialCategory.id);

      initialProductSelected && setSelectedProducts([initialProductSelected.id]);
      setCurrentCategory({ value: initialCategory.id, label: initialCategory.name });
    }

    if (isPackagePresent) {
      const firstProduct = productsAdded[0];
      handleContentsSelection(firstProduct?.id, 0);
      setSelectedProduct(initialProducts?.find((product) => product.id === firstProduct?.id));
    }
  };

  const handleProductsFilter = () => {
    const items = products.filter((item) => item.category.id === (currentCategory ? currentCategory.value : categories.length ? categories[0].id : null));

    setFilteredItems([...items]);
  };

  const handleProductCategoryChange = (newCategory) => {
    const newProductSelected = products.find((product) => product.category.id === newCategory.value);

    setCurrentCategory(newCategory);
    setSelectedContentIndex(null);
    setSelectedProduct(null);

    newProductSelected ? setSelectedProducts([newProductSelected.id]) : setSelectedProducts([]);
    setFirstSelected(0);
  };

  const handleSelection = (e) => {
    const product = e.target.dataset.product;
    const productIndex = parseInt(e.target.dataset.index);

    let selection;
    let active = selectedProducts.some((select) => select === product);

    setSelectedContentIndex(null);

    if (!isShiftPressed) {
      if (active) {
        selection = selectedProducts.filter((select) => select !== product);
      } else {
        selection = isCtrlPressed ? [...selectedProducts, product] : [product];
        setFirstSelected(productIndex);
        setLastSelected(null);
      }

      setSelectedProducts(selection);
    } else {
      setLastSelected(productIndex);
    }
  };

  const selectMultiple = (filteredItems) => {
    const multipleSelection = [...selectedProducts];

    if (firstSelected !== null && lastSelected !== null) {
      filteredItems.map((item, index) => {
        if (index > firstSelected && index <= lastSelected) {
          return multipleSelection.push(item.id);
        } else if (index >= lastSelected && index < firstSelected) {
          return multipleSelection.push(item.id);
        }
        return null;
      });

      setSelectedProducts(multipleSelection.filter((item, index) => multipleSelection.indexOf(item) === index));
    }
  };

  const handleProductsAdd = () => {
    const newProducts = [];

    selectedProducts.forEach((selected) => {
      const newProduct = products.find((product) => product.id === selected);

      newProduct &&
        newProducts.push({
          id: newProduct.id,
          name: shippingType !== 'drop_shipping' && newProduct.bulk_multiplier > 1 ? newProduct.bulk_name : newProduct.name,
          quantity: 1,
          photoday_price_cents: newProduct.photoday_price_cents,
          price_cents: newProduct.price_cents,
          bulk_price_cents: newProduct.bulk_price_cents,
          is_digital_download: newProduct.is_digital_download,
          digital_bundle_enabled: newProduct.digital_bundle_enabled
        });
    });

    onProductsAdd(newProducts);
    setSelectedProducts([]);

    // Auto-select the new added product
    handleSelectedImage(newProducts[0]?.id);
    setSelectedContentIndex(productsAdded?.length);
    onSelectedContentIndex(productsAdded?.length);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { index: startIndex } = result.source;
    const { index: endIndex } = result.destination;

    if (startIndex === endIndex) return null;
    if (selectedContentIndex === startIndex) setSelectedContentIndex(endIndex);
    if (selectedContentIndex >= endIndex && selectedContentIndex < startIndex) setSelectedContentIndex(selectedContentIndex + 1);
    if (selectedContentIndex <= endIndex && selectedContentIndex > startIndex) setSelectedContentIndex(selectedContentIndex - 1);

    onDragEnd(result);
  };

  const handleSelectedImage = (selectedProductId) => {
    const newProduct = allProducts.find((product) => product.id === selectedProductId);

    setSelectedProduct(newProduct);
  };

  const handleContentsSelection = (id, index) => {
    setSelectedContentIndex(index);
    setSelectedProducts([]);
    handleSelectedImage(id);
    onSelectedContentIndex(index);
  };

  const handleKeyDown = (e) => {
    e.which === 16 && setIsShiftPressed(true);
    e.which === 17 && setIsCtrlPressed(true);
    e.which === 93 && setIsCtrlPressed(true);
  };

  const handleKeyUp = (e) => {
    e.which === 16 && setIsShiftPressed(false);
    e.which === 17 && setIsCtrlPressed(false);
    e.which === 93 && setIsCtrlPressed(false);
  };

  const BulkSection = () => {
    const addedProductSelected = !selectedProducts.length && selectedProduct;
    const bulkProduct = addedProductSelected && bulkProductsAdded.find((product) => product.id === selectedProduct.id);
    const selectedBulkProduct = bulkProduct && productsAdded.find((product) => product.id === bulkProduct.id);
    const quantity = selectedBulkProduct && selectedBulkProduct.quantity ? selectedBulkProduct.quantity : '';

    return (
      <div className="products-selection__bulk-container">
        {quantity && selectedContentIndex !== null && (
          <p>
            {(shippingType === 'bulk_shipping' || selectedProduct.bulk_price_cents > selectedProduct.price_cents) &&
              `*Retail price calculated using Bulk price: $${formatCurrency(selectedProduct.bulk_price_cents)}`}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (selectedProducts.length === 1) {
      handleSelectedImage(selectedProducts[0]);
    }

    if (selectedProducts.length > 1) {
      handleSelectedImage();
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (lastSelected !== null) {
      selectMultiple(filteredItems);
    }
  }, [lastSelected]);

  useEffect(() => {
    handleProductsFilter();
  }, [currentCategory.value, products]);

  useEffect(() => {
    initialValuesSetup();
  }, [excludeProducts]);

  useEffect(() => {
    initialValuesSetup();
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <main className="flex modal__content">
      <section className="flex-6 flex-12-md modal__content-section">
        <h3>Add Products</h3>

        {/* Catalog */}
        <fieldset className="flex column flex-12 fieldset--clean">
          <label htmlFor="name">Catalog</label>
          <Select
            className="select"
            classNamePrefix="select"
            value={currentCategory}
            onChange={handleProductCategoryChange}
            options={categoryOptions}
            placeholder={categories.length === 0 ? 'Loading...' : ''}
          />
        </fieldset>

        {/* Product list */}
        <div className="flex-12 products-selection__table-container catalog-table" tabIndex="1">
          {filteredItems.length === 0 ? (
            requesting ? (
              <TableLoader rows={3} height={37} />
            ) : (
              <p className="products-selection__empty-list text--grey">All products have been added.</p>
            )
          ) : (
            <table width="100%">
              <tbody className="table-selector__list" onClick={handleSelection}>
                {filteredItems.map((item, index) => (
                  <tr
                    className={`table-selector__item ${selectedProducts.some((select) => select === item.id) ? 'table-selector__item--active' : ''}`}
                    data-product={item.id}
                    data-index={index}
                    key={item.id}
                  >
                    <td colSpan="1">{shippingType !== 'drop_shipping' && item.bulk_multiplier > 1 ? `${item.bulk_name}` : `${item.name}`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Product Image */}
        <figure className="flex gap-10 nowrap mb-10">
          {selectedProduct ? (
            <>
              <img className="flex-6 image--cover" src={selectedProduct.image_url} alt={selectedProduct.name} height="150" />
              <figcaption>
                <strong>
                  {shippingType !== 'drop_shipping' && selectedProduct.bulk_multiplier > 1 ? `${selectedProduct.bulk_name}` : `${selectedProduct.name}`}:{' '}
                </strong>
                {selectedProduct.description}
              </figcaption>
            </>
          ) : (
            <p className="flex middle center text--grey">Select a product below to preview it.</p>
          )}
        </figure>
        {selectedProduct?.is_digital_download && (
          <aside className="panel panel--lean panel--gray">
            <small className="text--bold">This product has a $2.00 minimum.</small>
          </aside>
        )}
        <button className="button button--medium button--center mb-20 mt-20" type="button" disabled={filteredItems.length === 0} onClick={handleProductsAdd}>
          {isPackage ? 'Add to package' : 'Add Product(s)'}
        </button>

        {/* Product & Package Content */}
        <h6>{isPackage ? 'Package Contents' : 'A La Carte'}</h6>
        <div className="products-selection__table-container products-contents-table">
          {productsAdded.length === 0 ? (
            <p className="products-selection__empty-list text--grey">Please add a product.</p>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={'productsAdded'} isDropDisabled={!isPackage}>
                {(provided) => (
                  <table width="100%" ref={provided.innerRef}>
                    <tbody>
                      {productsAdded.map((item, index) => (
                        <ItemComponent
                          item={item}
                          key={index}
                          isPackage={isPackage}
                          removeHandler={onProductRemove}
                          onChange={onQuantityChange}
                          showQuantity={true}
                          onSelectRow={handleContentsSelection}
                          index={index}
                          selected={selectedContentIndex}
                        />
                      ))}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        {errors.contents && <p className="errors-container">{errors.contents}</p>}
      </section>

      {/* Product Setup */}
      <section className="flex-6 flex-12-md modal__content-section">
        <h2>Product Setup</h2>
        {selectedContentIndex !== null ? (
          <>
            {isPackage === false && DOWNLOAD_DIGITALS_ENABLED && selectedProduct?.digital_bundle_enabled ? (
              <DigitalProductSetup
                priceFieldValues={priceFieldValues}
                priceFieldError={errors.profit || errors.digital || errors.contents}
                digitalProductBundle={digitalProductBundleAdded}
                onPriceChange={onPriceChange}
                onBundlePricingTypeChange={onBundlePricingTypeChange}
                onBundlePricingChange={onBundlePricingChange}
                onBundleTierChange={onBundleTierChange}
              />
            ) : (
              <div className="flex flex-row">
                <div className="flex-12">
                  <h5>{priceFieldValues.label}</h5>
                  <p>
                    <small>{priceFieldValues.message}</small>
                  </p>
                  <div className="flex flex-row">
                    <fieldset className="flex column flex-4">
                      <label>
                        <Tooltip title={baseCostTip} position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                          Base Cost
                          <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-question-circle-o" />
                          </a>
                        </Tooltip>
                      </label>
                      <input type="text" name="baseCostCents" value={priceFieldValues.baseCostText} required placeholder="0" disabled={true} />
                    </fieldset>
                    <fieldset className="flex column flex-4">
                      <label>
                        <Tooltip title="Retail Price minus Base Cost" position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                          Markup
                          <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-question-circle-o" />
                          </a>
                        </Tooltip>
                      </label>
                      <input
                        type="text"
                        name="markupCents"
                        value={priceFieldValues.markupText}
                        style={priceFieldValues.markupCents < 1 ? { color: '#a94442' } : {}}
                        required
                        placeholder="0"
                        disabled={true}
                      />
                    </fieldset>
                    <fieldset className="flex column flex-4">
                      <label>
                        <Tooltip title={`${pdFee}% of Retail Price`} position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                          PhotoDay Fee
                          <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-question-circle-o" />
                          </a>
                        </Tooltip>
                      </label>
                      <input
                        type="text"
                        name="priceCents"
                        value={priceFieldValues.photoDayFeeText}
                        style={priceFieldValues.priceCents < priceFieldValues.minimumPriceCents ? { color: '#a94442' } : {}}
                        required
                        placeholder="0"
                        disabled={true}
                      />
                    </fieldset>

                    <div className="flex flex-row">
                      <fieldset className="flex column flex-4">
                        <label>
                          {' '}
                          <Tooltip title="Amount displayed on price sheet" position="top" trigger="mouseenter" arrow={true} zIndex={10000}>
                            Retail Price
                            <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                              <i className="fa fa-question-circle-o" />
                            </a>
                          </Tooltip>
                        </label>
                        <NumericFormat
                          className="input--block"
                          name="priceCents"
                          prefix={'$'}
                          value={priceFieldValues.formattedValue}
                          allowNegative={false}
                          decimalScale={2}
                          onValueChange={onPriceChange}
                          style={enableSubmit ? { borderWidth: '2px', borderColor: '#7dd322' } : {}}
                        />
                      </fieldset>
                    </div>

                    {isPackage ? (
                      <>
                        {errors.profit && <p className="flex-12 text--bold text--danger">{errors.profit}</p>}
                        {enableSubmit && <p className="flex-12 text--bold text--danger">Please check your price one last time before saving!</p>}
                      </>
                    ) : (
                      <>
                        {errors.profit &&
                          productsAdded[selectedContentIndex] &&
                          productsAdded[selectedContentIndex].retailPrice < productsAdded[selectedContentIndex].minimumPriceCents && (
                            <p className="flex-12 text--danger">{`Minimum retail price must be ${convertToText(
                              productsAdded[selectedContentIndex].minimumPriceCents,
                              '$'
                            )} or higher.`}</p>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="products-selection__placeholder text--grey">
            <p>To update product pricing select a product.</p>
          </div>
        )}

        {BULK_SHIPPING_ENABLED && shippingType !== 'drop_shipping' && bulkProductsAdded.length > 0 && <BulkSection />}
      </section>
    </main>
  );
};

ProductsSelector.propTypes = {
  pdFee: PropTypes.number.isRequired,
  labId: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  isPackage: PropTypes.bool,
  requesting: PropTypes.bool,
  excludedCategories: PropTypes.array.isRequired,
  productsAdded: PropTypes.array.isRequired,
  packageValues: PropTypes.object.isRequired,
  formattedValue: PropTypes.string,
  currentSection: PropTypes.string.isRequired,
  excludeProducts: PropTypes.array.isRequired,
  enableSubmit: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onProductsAdd: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onBundlePricingChange: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired
};

export default ProductsSelector;
