import { Component } from 'react';

// Plugins
import queryString from 'query-string';

// Components
import OrderRow from './order-row';
import PropTypes from 'prop-types';
import SearchBox from '@/components/Shared/SearchBox';
import PhotoDayGrid from '@/components/Shared/PhotodayGrid';

// Redux
import { connect } from 'react-redux';
import { getJobTimezoneList } from '../../Jobs/actions';
import { orderListRequest, setOrderNameSearch } from './actions';

// Styles
import './orders.css';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      studio: { id: studioId }
    },
    jobs: { timezones }
  } = state;

  const { search: querySearch } = queryString.parse(ownProps.location.search);

  return {
    studioId,
    timezones,
    querySearch,
    ...state.orders,
    pager: state.orders.pager,
    isSearchRequesting: state.orders.isSearchRequesting
  };
};

const mapDispatchToProps = { orderListRequest, setOrderNameSearch, getJobTimezoneList };

class Orders extends Component {
  handleFilterSearchOnChange = (value) => {
    const { studioId, pager: origPager } = this.props;

    this.props.setOrderNameSearch({
      ...origPager,
      search: value,
      page: 1,
      studioId
    });
  };

  componentDidMount() {
    const {
      querySearch,
      pager: { page, perPage },
      orderListRequest,
      studioId,
      timezones,
      getJobTimezoneList
    } = this.props;

    orderListRequest({ studioId, page, perPage, order: 'submitted_at', dir: 'DESC', search: querySearch });

    if (!timezones.length) {
      getJobTimezoneList();
    }
  }

  fetchRecords = (pager) => {
    const { orderListRequest, studioId } = this.props;

    orderListRequest({ studioId, ...pager });
  };

  render() {
    const { result, entities, pager, requesting, querySearch, isSearchRequesting, timezones } = this.props;

    const gridHeader = [
      {
        fieldName: 'submitted_at',
        displayName: 'Date',
        sortable: true
      },
      {
        fieldName: 'num',
        displayName: 'Order #',
        sortable: true
      },
      {
        fieldName: 'advance_pay',
        displayName: 'Type',
        sortable: false
      },
      {
        fieldName: 'job.name',
        displayName: 'Job',
        sortable: true
      },
      {
        fieldName: 'customer.name',
        displayName: 'Customer Name',
        sortable: false
      },
      {
        fieldName: 'status',
        displayName: 'Status',
        sortable: true
      },
      {
        fieldName: 'tracking_number',
        displayName: 'Tracking #',
        sortable: false
      },
      {
        fieldName: 'credit',
        displayName: 'Credit Applied',
        sortable: false
      },
      {
        fieldName: 'total_with_credit',
        displayName: 'Order Total',
        sortable: false
      }
    ];

    return (
      <section id="storefront__orders">
        <div className="flex middle between">
          <div>
            <h3 className="text--bold">Orders</h3>
            <p className="text--black">Keep track of all your orders below. Orders can take up to 12 hours to process.</p>
          </div>
          <SearchBox value={querySearch} onChange={this.handleFilterSearchOnChange} placeholder="Search Orders" requesting={isSearchRequesting} />
        </div>

        <PhotoDayGrid
          headers={gridHeader}
          table_id="order-table"
          pager={pager}
          fetchRecordsPage={this.fetchRecords}
          borderClass="yellow-border color-border"
          requesting={requesting}
          defaultContent="You currently have no orders."
        >
          {result.map((id) => {
            const order = entities.orders[id];

            return <OrderRow key={id} order={order} timezones={timezones} />;
          })}
        </PhotoDayGrid>
        <p className="order-annotation"> *Order total before applied AP credit</p>
      </section>
    );
  }
}

Orders.propTypes = {
  result: PropTypes.arrayOf(PropTypes.string),
  entities: PropTypes.shape({
    jobs: PropTypes.object
  }).isRequired,
  pager: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }).isRequired,
  requesting: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
