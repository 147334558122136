import { Component } from 'react';

class DownloadFileLink extends Component {
  render() {
    const { filename, psbFilename, title, psdtFilename, description, videoUrl, height } = this.props;

    return (
      <div className="flex between resources-container__item-footer" style={{ height }}>
        {title && description ? (
          <>
            <h6 className="text--block text--nomargin">{title}</h6>
            <p className="text--small text--nomargin text--preline mt-10 mb-10">{description}</p>
          </>
        ) : (
          <h6 className="text--block text--nomargin mt-10 mb-10">{title}</h6>
        )}
        <aside>
          {filename && (
            <a href={filename} className="download-link" target="_blank" rel="noopener noreferrer" download>
              <small>Download PDF</small>
            </a>
          )}
          {psbFilename && (
            <a href={`https://media.photoday.io/psds/${psbFilename}.psb`} className="download-link" download>
              <small>Download PSB</small>
            </a>
          )}
          {psdtFilename && (
            <a href={psdtFilename} className="download-link" target="_blank" rel="noopener noreferrer" download>
              <small>Download PSDT</small>
            </a>
          )}
          {videoUrl && (
            <a href={videoUrl} className="download-link" target="_blank" rel="noopener noreferrer" download>
              <small>Download Video</small>
            </a>
          )}
        </aside>
      </div>
    );
  }
}

export default DownloadFileLink;
